<template>
    <div class="page-domains-register-domain-confirm-registration container">
        <app-loader v-if="processing" fixed-on="desktop"></app-loader>

        <div class="row">
            <div class="col-12">
                <h1 class="heading">Confirm Registration</h1>
            </div>

            <div class="col-12">
                <app-error v-model="errors.show" :message="errors.message"></app-error>

                <app-table
                    class="domain-list-table"

                    v-model="selected"

                    :sort-value="filter.sort"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"

                    @select-renew-period="selectRenewPeriod"
                ></app-table>

                <div class="footer">
                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />

                    <button class="btn btn-primary btn-confirm-registration"
                        :disabled="loading || processing"

                        @click="confirmRegistration"
                    >Confirm registration</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appLoader from '@/components/app-loader'
import appPagination from '@/components/app-pagination'

import errMessage from '@/helpers/errMessage'

const DOMAIN_LIST = [
    {
        UUID: '1fcf0373-0186-4896-9d7d-9cfdf69f6b06',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: '11bdb3ea-0bec-4b0f-a34a-578f83c96f1c',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: true,
    },
    {
        UUID: '84ffb32f-062c-44dc-b366-a4c2a0fd781c',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: '465fd826-9a0e-4196-8c25-c963ce8e12f7',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: true,
    },
    {
        UUID: '5e42f98e-063a-4ed6-9667-00953671d6d3',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: '27f40887-77f8-42a0-a13c-308ed4d2b753',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: '2e211d9b-e7af-4a2c-afe0-865836c73eea',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: 'cc62c4e5-1627-401d-bf7d-d480b3dff2ce',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: true,
    },
    {
        UUID: '5613aea1-fa6f-42aa-a04f-7db44a97d8ec',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: '8eb9df92-052f-4796-ba2a-9ef2cb0ba91f',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: true,
    },
    {
        UUID: '02db5d37-01e4-4099-8572-dee069cdc9ca',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: true,
    },
    {
        UUID: '03b5204d-f92f-40cb-8d62-95eb901d0f07',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: '9cbd664e-4477-457a-8690-28d29d06c4b3',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: true,
    },
    {
        UUID: '68f68b7b-4502-4cb7-9624-7c502f7f8210',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: 'e15f8f18-5580-4f40-b387-84247e198b0d',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: false,
    },
    {
        UUID: 'dbb55eb6-65f5-41c5-b0a7-e4033615e25c',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: true,
    },
    {
        UUID: '2ac5720d-c155-4c74-b89c-787854feeb2a',
        DomainName: 'domain.name.com',
        Availability: 'Lorem ipsum dolor sit amet',
        RenewPeriod: '1-year',
        Cents: 2999,
        Disabled: true,
    },
]

const renew_period_options = [
    {
        value: '1-year',
        title: '1 year',
    },
    {
        value: '2-years',
        title: '2 years',
    },
    {
        value: '3-years',
        title: '3 years',
    },
]

export default {
    components: {
        appError,
        appTable,
        appLoader,
        appPagination,
    },

    data() {
        return {
            domains_for_searching: [],

            filter: {
                sort: {
                    field: 'Cents',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'DomainName',   title: 'Domain name'  },
                { field: 'Availability', title: 'Availability' },
                { field: 'Cents',        title: 'Price'        },
            ],

            cols: [
                { key: 'DomainName',   title: 'Domain Name',  sort: { field: 'DomainName' },   type: 'checkbox',                                                               },
                { key: 'Availability', title: 'Availability', sort: { field: 'Availability' },                                                                                 },
                { key: 'RenewPeriod',  title: 'Renew Period',                                  type: 'select',   options: renew_period_options, action: 'select-renew-period', },
                { key: 'Price',        title: 'Price',        sort: { field: 'Cents' },                                                                                        },
            ],

            domain_list: [],

            selected: {},

            renew_period: {},

            loading: false,
            processing: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getDomainList()

            for (let i = 0, len = DOMAIN_LIST.length; i < len; i++) {
                const domain = DOMAIN_LIST[i]

                if (!domain.Disabled) {
                    this.selected[domain.UUID] = true
                }
            }

            this.domains_for_searching = this.$route.query.domains
                ? this.$route.query.domains.split(',')
                : []

            // console.log(JSON.parse(JSON.stringify(this.domains_for_searching)))
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getDomainList()
        },

        onPageChange(page) {
            this.getDomainList(page)
        },

        getDomainList(page) {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                const list = []

                const start = ( ( page || this.pagination.page ) - 1 ) * this.pagination.limit
                const total = DOMAIN_LIST.length
                const end = start + this.pagination.limit < total ? start + this.pagination.limit : total

                for (let i = start; i < end; i++) {
                    list.push(DOMAIN_LIST[i])
                }

                this.domain_list = list

                this.pagination.page = page || this.pagination.page
                this.pagination.total = total

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({
                        name: this.$route.name,
                        params: { page: this.pagination.page },
                        query: this.$route.query,
                        replace: true
                    })
                }

                this.errors = {
                    show: false,
                    message: '',
                }

                this.loading = false
            }, delay_in_ms)
        },

        selectRenewPeriod(data) {
            this.renew_period = {
                ...this.renew_period,
                ...data,
            }
        },

        confirmRegistration() {
            const delay_in_ms = 1000

            this.processing = true

            setTimeout(() => {
                this.processing = false
            }, delay_in_ms)
        },
    },

    computed: {
        rows() {
            const rows = []

            for (let i = 0, len = this.domain_list.length; i < len; i++) {
                const domain = this.domain_list[i]

                const UUID = domain.UUID

                const RenewPeriod = this.renew_period[UUID]
                    ? this.renew_period[UUID]
                    : domain.RenewPeriod

                rows.push({
                    uuid:         UUID,
                    disabled:     domain.Disabled,
                    DomainName:   domain.DomainName,
                    Availability: domain.Availability,
                    RenewPeriod:  RenewPeriod,
                    Price:        `$${ ( domain.Cents / 100 ).toFixed(2) }`,
                })
            }

            return rows
        },
    },
}
</script>

<style lang="scss">
.page-domains-register-domain-confirm-registration {
    $btn-max-width: 200px;

    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .domain-list-table {
        @include table-cols-width((250px, 300px, 200px, 150px), true);
    }

    .footer {
        display: flex;

        .app-pagination {
            width: calc(100% - #{ $btn-max-width });
            margin-top: 30px;
        }

        .btn-confirm-registration {
            margin-top: 30px;
            margin-left: auto;

            max-width: $btn-max-width;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-domains-register-domain-confirm-registration {
        .domain-list-table {
            .app-table-box {
                .row {
                    .select-trigger {
                        min-width: 100px;
                    }
                }
            }

            @include table-cols-width((200px, 200px, 150px, 100px), true);
        }

        .footer {
            flex-direction: column;

            .app-pagination {
                width: 100%;
            }

            .btn-confirm-registration {
                margin-left: initial;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-domains-register-domain-confirm-registration {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .domain-list-table {
            .app-table-box {
                .row {
                    .select-trigger {
                        min-width: 100%;
                    }
                }
            }

            @include table-cols-width-mobile((137px, 137px), true);
        }

        .footer {
            .app-pagination,
            .btn-confirm-registration {
                margin-top: 15px;
            }
        }
    }
}
</style>